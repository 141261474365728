import { Option } from "@swan-io/boxed";
import { BreadcrumbsRoot } from "fleming-lake/src/components/Breadcrumbs";
import { FullViewportLayer } from "fleming-lake/src/components/FullViewportLayer";
import { commonStyles } from "fleming-lake/src/constants/commonStyles";
import { useMemo } from "react";
import { View } from "react-native";
import { match } from "ts-pattern";
import { AccountCountry, CreditTransferMode } from "../graphql/partner";
import { useTransferToastWithRedirect } from "../hooks/useTransferToastWithRedirect";
import { NotFoundPage } from "../pages/NotFoundPage";
import { TransferPage } from "../pages/TransferPage";
import { t } from "../utils/i18n";
import { paymentRoutes, Router } from "../utils/routes";
import { TransferInternationalWizard } from "./TransferInternationalWizard";
import { TransferRecurringWizard } from "./TransferRecurringWizard";
import { TransferRegularWizard } from "./TransferRegularWizard";
import { TransferTypePicker } from "./TransferTypePicker";

type Props = {
  accountCountry: AccountCountry;
  accountId: string;
  accountMembershipId: string;
  canQueryCardOnTransaction: boolean;
  canViewAccount: boolean;
  canInitiatePayments: boolean;
  transferConsent: Option<{ status: string; isStandingOrder: boolean }>;
  userId: string;
  transferCreationVisible: boolean;
};

export const TransferArea = ({
  accountCountry,
  accountId,
  accountMembershipId,
  canQueryCardOnTransaction,
  canViewAccount,
  transferConsent,
  transferCreationVisible,
  canInitiatePayments,
  userId,
}: Props) => {
  const route = Router.useRoute(paymentRoutes);

  useTransferToastWithRedirect(transferConsent, () =>
    Router.replace(
      route?.name === "AccountPaymentsRecurringTransferList"
        ? "AccountPaymentsRecurringTransferList"
        : "AccountPaymentsRoot",
      { accountMembershipId },
    ),
  );

  const rootLevelCrumbs = useMemo(() => {
    return [
      {
        label: t("transfer.transfer"),
        link: Router.AccountPaymentsRoot({ accountMembershipId }),
      },
    ];
  }, [accountMembershipId]);
  return (
    <BreadcrumbsRoot rootLevelCrumbs={rootLevelCrumbs}>
      <View role="main" style={commonStyles.fill}>
        {match(route)
          .with(
            { name: "AccountPaymentsRoot" },
            { name: "AccountPaymentsRecurringTransferList" },
            { name: "AccountPaymentsRecurringTransferDetailsArea" },
            { name: "AccountPaymentsPreparedTransfers" },
            { name: "AccountPaymentsPreparedTransferDetailsArea" },
            () => (
              <TransferPage
                userId={userId}
                accountId={accountId}
                accountMembershipId={accountMembershipId}
                transferCreationVisible={transferCreationVisible}
                canQueryCardOnTransaction={canQueryCardOnTransaction}
                canViewAccount={canViewAccount}
                canInitiatePayments={canInitiatePayments}
              />
            ),
          )
          .with({ name: "AccountPaymentsNew" }, ({ params: { type } }) =>
            transferCreationVisible ? (
              <>
                <TransferTypePicker accountMembershipId={accountMembershipId} />

                <FullViewportLayer visible={type === "transfer"}>
                  <TransferRegularWizard
                    userId={userId}
                    canInitiatePayments={canInitiatePayments}
                    accountCountry={accountCountry}
                    accountId={accountId}
                    accountMembershipId={accountMembershipId}
                    onPressClose={() =>
                      Router.push("AccountPaymentsPreparedTransfers", { accountMembershipId })
                    }
                  />
                </FullViewportLayer>

                <FullViewportLayer visible={type === "recurring"}>
                  <TransferRecurringWizard
                    canInitiatePayments={canInitiatePayments}
                    accountCountry={accountCountry}
                    accountId={accountId}
                    accountMembershipId={accountMembershipId}
                    onPressClose={() => Router.push("AccountPaymentsRoot", { accountMembershipId })}
                  />
                </FullViewportLayer>

                <FullViewportLayer visible={type === "international"}>
                  <TransferInternationalWizard
                    accountId={accountId}
                    accountMembershipId={accountMembershipId}
                    onPressClose={() => Router.push("AccountPaymentsNew", { accountMembershipId })}
                  />
                </FullViewportLayer>

              </>
            ) : (
              <NotFoundPage />
            ),
          )
          .with(
            { name: "AccountPaymentsRepeat" },
            ({ params: { type, name, IBAN, amount, currency, label, reference } }) =>
              transferCreationVisible ? (
                <>
                  <TransferTypePicker accountMembershipId={accountMembershipId} />

                  <FullViewportLayer visible={type === "transfer"}>
                    <TransferRegularWizard
                      name={name}
                      userId={userId}
                      IBAN={IBAN}
                      accountId={accountId}
                      accountCountry={accountCountry}
                      amount={amount}
                      currency={currency}
                      label={label}
                      reference={reference}
                      accountMembershipId={accountMembershipId}
                      canInitiatePayments={canInitiatePayments}
                      onPressClose={() =>
                        Router.push("AccountPaymentsRoot", { accountMembershipId })
                      }
                    />
                  </FullViewportLayer>

                  <FullViewportLayer visible={type === "recurring"}>
                    <TransferRecurringWizard
                      canInitiatePayments={canInitiatePayments}
                      accountId={accountId}
                      accountCountry={accountCountry}
                      accountMembershipId={accountMembershipId}
                      onPressClose={() =>
                        Router.push("AccountPaymentsRoot", { accountMembershipId })
                      }
                    />
                  </FullViewportLayer>
                </>
              ) : (
                <NotFoundPage />
              ),
          )
          .with(
            { name: "AccountPaymentsEdit" },
            ({
              params: { preparedTransferId, type, name, IBAN, amount, currency, label, reference, requestedExecutionAt, mode },
            }) =>
              transferCreationVisible ? (
                <>
                  <TransferTypePicker accountMembershipId={accountMembershipId} />

                  <FullViewportLayer visible={type === "transfer"}>
                    <TransferRegularWizard
                      name={name}
                      userId={userId}
                      IBAN={IBAN}
                      accountId={accountId}
                      accountCountry={accountCountry}
                      amount={amount}
                      currency={currency}
                      label={label}
                      reference={reference}
                      requestedExecutionAt={requestedExecutionAt}
                      mode={mode as CreditTransferMode}
                      accountMembershipId={accountMembershipId}
                      canInitiatePayments={canInitiatePayments}
                      preparedTransferId={preparedTransferId}
                      onPressClose={() =>
                        Router.push("AccountPaymentsRoot", { accountMembershipId })
                      }
                    />
                  </FullViewportLayer>

                  <FullViewportLayer visible={type === "recurring"}>
                    <TransferRecurringWizard
                      canInitiatePayments={canInitiatePayments}
                      accountId={accountId}
                      accountCountry={accountCountry}
                      accountMembershipId={accountMembershipId}
                      onPressClose={() =>
                        Router.push("AccountPaymentsRoot", { accountMembershipId })
                      }
                    />
                  </FullViewportLayer>
                </>
              ) : (
                <NotFoundPage />
              ),
          )
          .otherwise(() => (
            <NotFoundPage />
          ))}
      </View>
    </BreadcrumbsRoot>
  );
};
